<template>
    <div>

        <div class="flex flex-col p-10">
            <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                Video Lectures Loh-o-qalam
            </h1>

            <div class="border-b border-gray-200 dark:border-gray-700">
                <ul class="flex justify-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 ">
                    <li class="mr-2">
                        <a @click.prevent="changeTab('Chromopathy')"
                            class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                            <font-awesome-icon icon="fa-solid fa-language"
                                class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                            Chromopathy
                        </a>
                    </li>

                    <li class="mr-2">
                        <a @click.prevent="changeTab('NaziriyaRangoNoor')"
                            class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                            <font-awesome-icon icon="fa-solid fa-book-quran"
                                class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                            Naziriya Rang o Noor
                        </a>
                    </li>

                    <li class="mr-2">
                        <a @click.prevent="changeTab('SilsilaeAzeemia')"
                            class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                            <font-awesome-icon icon="fa-solid fa-book-quran"
                                class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                            Silsila-e-Azeemia
                        </a>
                    </li>

                    <li class="mr-2">
                        <a @click.prevent="changeTab('LohoQalam')"
                            class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                            <font-awesome-icon icon="fa-solid fa-book-quran"
                                class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                            Loh o Qalam
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="Chromopathy">Chromopathy</div>
            <div v-if="NaziriyaRangoNoor">NaziriyaRangoNoor</div>
            <div v-if="SilsilaeAzeemia">SilsilaeAzeemia</div>
            <div v-if="LohoQalam">Loh o Qalam Page</div>
        </div>

        <div class="bg-gray-100">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <h2 class="text-2xl font-bold text-gray-900">Videos</h2>

                    <div class="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        <div v-for="video in videos" :key="video.id.videoId" class="group relative">
                            <!-- <div class="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                        <img :src="video.img" :alt="video.title" class="h-80 w-0 object-cover object-center" />
                        </div> -->
                            <h3 class="mt-6 text-sm text-gray-500">
                                <div class="flex flex-row p-1">
                                    <iframe width="560" height="315"
                                        :src="'https://www.youtube.com/embed/' + video.id.videoId"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </div>
                                <!-- <a :href="video.url">
                            <span class="absolute inset-0" />
                            {{ video.utitle }} {{ video.title }}{{ video.utitle2 }}
                        </a> -->
                            </h3>
                            <!-- <p class="text-base font-semibold text-gray-900">Views: {{ video.etag }}; 
                            Old: {{ video.old }} Duration {{ video.time }}</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, onBeforeMount } from 'vue'
import axios from '../axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import useMainStore from "../stores/index";

const store = useMainStore();

const lohoqalamVideos = ref();
const chromopathyVideos = ref();

onBeforeMount(() => {
    getYTVideos();
});

onBeforeMount(() => {
    axios.get("/video/lohoqalamV")
        .then((response) => {
            lohoqalamVideos.value.data = response.data;
            // console.log('English Books: '+JSON.stringify(Ebooks.value.data));
        });

    axios.get("/video/chromopathy")
        .then((response) => {
            Ubooks.value.data = response.data;
            // console.log('Urdu Books: '+JSON.stringify(Ubooks.value.data));
        });
});

const Chromopathy = ref(false)
const NaziriyaRangoNoor = ref(false)
const SilsilaeAzeemia = ref(false);
const LohoQalam = ref(false);

function changeTab(val) {

    switch (val) {
        case 'Chromopathy':
            Chromopathy.value = true;
            NaziriyaRangoNoor.value = false;
            SilsilaeAzeemia.value = false;
            LohoQalam.value = false;
            break;

        case 'NaziriyaRangoNoor':
            Chromopathy.value = false;
            NaziriyaRangoNoor.value = true;
            SilsilaeAzeemia.value = false;
            LohoQalam.value = false;
            break;

        case 'SilsilaeAzeemia':
            Chromopathy.value = false;
            NaziriyaRangoNoor.value = false;
            SilsilaeAzeemia.value = true;
            LohoQalam.value = false;
            break;

        case 'LohoQalam':
            Chromopathy.value = false;
            NaziriyaRangoNoor.value = false;
            SilsilaeAzeemia.value = false;
            LohoQalam.value = true;
            break;
    }
}
</script>


<style scoped></style>
