<template>
    <div>
        <h1>Quran page</h1>
        <!-- Pseudocode 

    Corpus
        mada    (consult the pdf)

        video surah
        audio
        video


        quran

            1. alsadaf

            2. surahs


        lectures
            1. loh-oqalam
            2. chropathy
            3. Nazriya rag o noor

        

 -->
    </div>
</template>

<script setup>

</script>


<style scoped>

</style>
