import { ref, computed } from 'vue';
import { defineStore } from "pinia";
import axios from "../axios";

export const useMainStore = defineStore("main", () => {

    const user = ref({
      token: null,// sessionStorage.getItem('TOKEN'),  
        data: [],
        links: [],
    });

    // const menus = ref({});
    const books = ref({})
    const videos = ref({})

    /*
    const getToken = computed(() => {
      return user.token
    })

    const getUser = computed(() => {
      return user.data
    })
    */

    function setToken(token){
      user.token = token;
      // console.log('store token:'+ user.token);
    }

    function setUser(data){
      user.data = data;
      // console.log("store user:"+user.data);
    }

    function getUser(){
      // console.log("Store user:"+user.data);
      return user.data;
    }

    function getToken(){
      // console.log("Store user:"+user.data);
      return user.token;
    }

    // const getMenu = computed(() => {
    //     axios.get("/menu").then((response) => {
    //         menu.value = response.data;
    //         console.log("Menu :" + JSON.stringify(menu.value));
    //     });
    // });


    const getBooks = computed(() => {
       axios.get("books").then((response) => { 
          books.value = response.data;
          // console.log("Books :" + JSON.stringify(books.value));
      });
    });

    const getVideos = computed(() => {
      axios.get("videos").then((response) => {
         videos.value = response.data;
         // console.log("Books :" + JSON.stringify(books.value));
     });
   });

    /*
      const getVideos = computed(() => {
        axios.get("/api/videos").then((response) => {
            videos.value = response.data;
            console.log("Videos :" + JSON.stringify(videos.value));
        });
  
  });
*/

    // function setBooks() { // data = null
    //   axios
    //     .get("http://localhost:8000/api/district") // , data
    //     .then((response) => {
    //       districts.value.data = response.data;
    //       // console.log("District after :" + JSON.stringify(districts.value.data));
    //     });
    // }

    return { user, books, getBooks, videos, getVideos, setToken, setUser, getToken, getUser };
});

export default useMainStore;
