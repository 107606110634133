<script setup>

import { Popover, PopoverButton, PopoverGroup, PopoverPanel, } from '@headlessui/vue';
import { ChevronDownIcon, } from '@heroicons/vue/20/solid';

import { library } from '@fortawesome/fontawesome-svg-core'

// import {  } from '@fortawesome/free-regular-svg-icons'

import { faRightToBracket, faCopyright } from '@fortawesome/free-solid-svg-icons'

import { faIdCard, } from '@fortawesome/free-regular-svg-icons'

library.add(faRightToBracket, faIdCard, faCopyright)

const menuu = [
    { name: 'Login', description: 'Login to the system', href: '/login', icon: 'fa-solid fa-right-to-bracket' },
    { name: 'Register', description: 'Register a new account ', href: '/register', icon: 'fa-regular fa-id-card' },
]

const currentYear = (new Date()).getFullYear();

</script>

<template>
    <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="flex justify-center mt-5">
                <img src="../assets/images/icon.svg" alt="Icon">
            </div>

            <div
                class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">

                <div class="ml-4 col-span-3 text-center text-sm text-gray-500 dark:text-gray-400 sm:text-right sm:ml-0">
                    <font-awesome-icon icon=" fa-copyright" /> DrAzeemi.com {{ currentYear }}  
                    | Designed & Managed by Adeel Raza Azeemi  Get All Rights Reserved.
                </div>

                <!-- <a href="https://ksars.org">
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1 cursor-pointer"
                        src="https://ksars.org/img/logo.png" alt="Transistor" width="158" height="48" />
                </a>
                <a href="https://iseek.online">
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1 cursor-pointer"
                        src="https://iseek.online/wp-content/uploads/2016/01/logo_iseek-1-e1453097807536.png"
                        alt="Reform" width="158" height="48" />
                </a> -->
                <!-- ------------------------------------------------------------------------- Submenu -->
                <nav class="mx-auto flex max-w-7xl items-end justify-between p-6 lg:px-8 
       flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 " aria-label="Global">

                    <PopoverGroup class="hidden lg:flex lg:gap-x-12">
                        <Popover class="relative">
                            <PopoverButton
                                class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                                <font-awesome-icon icon="fa-solid fa-right-to-bracket"
                                    class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                                Auth
                                <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            </PopoverButton>

                            <transition enter-active-class="transition ease-out duration-200"
                                enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                                leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                <PopoverPanel
                                    class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div class="p-4">
                                        <div v-for="item in menuu" :key="item.name"
                                            class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                            <div
                                                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <font-awesome-icon :icon="item.icon"
                                                    class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                                    aria-hidden="true" />
                                            </div>
                                            <div class="flex-auto">
                                                <router-link :to="item.href" class="block font-semibold text-gray-900">
                                                    {{ item.name }}
                                                    <span class="absolute inset-0" />
                                                </router-link>
                                                <p class="mt-1 text-gray-600">{{ item.description }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </PopoverPanel>
                            </transition>
                        </Popover>

                    </PopoverGroup>
                </nav>
                <!-- ------------------------------------------------------------------------- End of submenu -->

            </div>
        </div>
    </div>
</template>
