<template>
    <div class="bg-white">

        <!-- Start of tabs -->
        <div class="border-b border-gray-200 dark:border-gray-700">
            <ul class="flex justify-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 ">
                <li class="mr-2">
                    <a @click.prevent="changeTab('english')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-language"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                        English
                    </a>
                </li>

                <li class="mr-2">
                    <a @click.prevent="changeTab('urdu')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-book-quran"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                        Urdu
                    </a>
                </li>
            </ul>
        </div>
        <!-- end of tabs -->

        <div v-if="flag.english">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> English Books</h2>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form class="space-y-6" @submit.prevent="summitHandle" method="GET">
                    <div>
                        <label for="search" class="block text-sm font-medium leading-6 text-gray-900">Search</label>
                        <div class="mt-2">
                            <input id="search" name="search" type="text" autocomplete="search" required=""
                                v-model="form.search"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                </form>
            </div>

            <p class="mt-4 text-gray-500">{{ Ebooks.description }}</p>
            <!-- Start of img tab -->
            <div class="mx-auto max-w-4xl">
                <ul role="list" class="divide-y divide-gray-100">
                    <li v-for="(book, key) in Ebooks.data" :key="key" class="flex justify-between gap-x-6 py-5">
                        <div class="flex min-w-0 gap-x-4">
                            <img class="h-12 w-12 flex-none rounded-full bg-gray-50"
                                :src="'/src/assets/images/books/' + book.category + '/' + book.name + '.jpg'"
                                alt="book.name" />
                            <div class="min-w-0 flex-auto">
                                <p class="text-sm font-semibold leading-6 text-gray-900">{{ book.name }}</p>
                                <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ book.size }}</p>
                            </div>
                        </div>
                        <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                            <p class="text-sm leading-6 text-gray-900">{{ }}</p>

                            <!-- <a :href="'/src/assets/books/' + book.category + '/' + book.name + '.pdf'">{{ book.name }}</a> -->

                            <div class="ml-4 flex-shrink-0">
                                <a :href="'/src/assets/books/' + book.category + '/' + book.name + '.pdf'"
                                    class="font-medium text-indigo-600 hover:text-indigo-500">Open</a>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
            <!-- End of img -->

        </div>

        <div v-if="flag.urdu">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Urdu Books</h2>
            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form class="space-y-6" @submit.prevent="summitHandle" method="GET">
                    <div>
                        <label for="search" class="block text-sm font-medium leading-6 text-gray-900">Search</label>
                        <div class="mt-2">
                            <input id="search" name="search" type="text" autocomplete="search" required=""
                                v-model="form.search"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                </form>
            </div>

            <p class="mt-4 text-gray-500">
                {{ Ubooks.description }}</p>

            <!-- Start of img tab -->
            <div class="mx-auto max-w-4xl">
                <ul role="list" class="divide-y divide-gray-100">
                    <li v-for="(book, key) in Ubooks.data" :key="key" class="flex justify-between gap-x-6 py-5">
                        <div class="flex min-w-0 gap-x-4">
                            <img class="h-12 w-12 flex-none rounded-full bg-gray-50"
                                :src="'/src/assets/images/books/' + book.category + '/' + book.name + '.jpg'"
                                alt="book.name" />
                            <div class="min-w-0 flex-auto">
                                <p class="text-sm font-semibold leading-6 text-gray-900">{{ book.name }}</p>
                                <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ book.size }}</p>
                            </div>
                        </div>
                        <div class="shrink-0 sm:flex sm:flex-col sm:items-end">
                            <p class="text-sm leading-6 text-gray-900">{{ }}</p>

                            <!-- <a :href="'/src/assets/books/' + book.category + '/' + book.name + '.pdf'">{{ book.name }}</a> -->

                            <div class="ml-4 flex-shrink-0">
                                <a :href="'/src/assets/books/' + book.category + '/' + book.name + '.pdf'"
                                    class="font-medium text-indigo-600 hover:text-indigo-500">Open</a>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
            <!-- End of img -->
        </div>
    </div>
</template>
  
<script setup>
import { reactive, ref, onBeforeMount, watch } from 'vue'
import axios from '../axios'

import {
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/vue/24/outline'

var form = reactive({ search: '' });

const flag = ref({
    'english': false,
    'urdu': false
});

const open = ref(false)

const Ebooks = ref({
    data: null,
    description: '.'
});
const Ubooks = ref({
    data: null,
    description: 'This channel is for those who are interested in understanding the mind, it\'s working and powers. And want to improve and build their minds.'
});

onBeforeMount(() => {
    axios.get("/books/English")
        .then((response) => {
            Ebooks.value.data = response.data;
            // console.log('English Books: '+JSON.stringify(Ebooks.value.data));
        });

    axios.get("/books/Urdu")
        .then((response) => {
            Ubooks.value.data = response.data;
            // console.log('Urdu Books: '+JSON.stringify(Ubooks.value.data));
        });
});


function changeTab(tab) {
    console.log('Clicked tab: ' + tab);
    switch (tab) {
        case 'english':
            flag.value.english = true;
            flag.value.urdu = false;
            break;

        case 'urdu':
            flag.value.english = false;
            flag.value.urdu = true;
            break;

    }
}

async function getBooks(type) {
    await axios.get("/books/" + type + "/" + form.search)
        .then((response) => {
            if (type == 'English')
                Ebooks.value.data = response.data;
            else if (type == 'Urdu')
                Ubooks.value.data = response.data;
            // console.log(calle + ": Tehsils: " + JSON.stringify(tehsils.value));
        })
        .catch(function (error) {
            console.log(error);
        });
}

const filteredList = () => {
    if (form.search == "") {
        console.log('Now in default mode');
        var val = flag.value.english ? "English" : "Urdu";
        getBooks(val);
    }
    else {
        var val = flag.value.english ? "English" : "Urdu";
        axios.get("/books/" + val + "/" + form.search)
            .then((response) => {
                if (val == 'English')
                    Ebooks.value.data = response.data;
                else if (val == 'Urdu')
                    Ubooks.value.data = response.data;
            });
    }
};

watch(
    () => form.search,
    (search, prevSearch) => {
        filteredList();
    }
);


</script>