        <template>
          <div>
              <div class="px-4 sm:px-0">
                  <h3 class="text-base font-semibold leading-7 text-gray-900">Question & Answer page</h3>
                  <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">.</p>
              </div>
              <div class="mt-6 border-t border-gray-100">
                  <dl class="divide-y divide-gray-100">
      
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt class="text-sm font-medium leading-6 text-gray-900">Audios</dt>
                          <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                                  <li v-for="(audio, key) in audios" :key="key" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                      <div class="flex w-0 flex-1 items-center">
                                          <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                          <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                              <span class="truncate font-medium">{{ audio.name }}</span>
                                              <span class="flex-shrink-0 text-gray-400">{{ audio.size }}</span>
                                          </div>
                                      </div>
                                      <div class="ml-4 flex-shrink-0">
                                              <!-- new URL('../assets/audio/Discussion/'+audio.name, import.meta.url).href -->
                                          <a :href="'/src/assets/audio/'+audio.group+'/'+audio.name" class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                      </div>
                                  </li>
                              </ul>
                          </dd>
                      </div>
                  </dl>
              </div>
          </div>
      </template>
      
      <script setup>
      import { PaperClipIcon } from '@heroicons/vue/20/solid'
      import { ref, computed, onBeforeMount } from 'vue'
      import axios from '../axios.js'
      
      const audios = ref({})
      
      onBeforeMount(() => {
        getQAudio();
      })
      
      async function getQAudio() {
          const response = await axios.get('/qna/Discussion');
          audios.value = response.data;
      }
      
      </script>