<template>
    <div class="bg-white">

        <div class="max-w-7xl mx-auto p-6 lg:p-8">

            <div class="flex justify-center mt-5">
                <img src="../assets/images/background.jpeg" alt="background">
            </div>

        </div>

    </div>
</template>
  
<script setup>

import { ref, reactive, watch, onBeforeMount, computed } from 'vue';
import axios from "axios";

import { Carousel, initTE, } from "tw-elements";
initTE({ Carousel });

import Coursal from '../components/coursal.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import useMainStore from "../stores/index";

const store = useMainStore();

import { faBook } from '@fortawesome/free-solid-svg-icons'

library.add(faBook)

// console.log('currentYear:' + JSON.stringify(currentYear.value));
// axios.get("/books")
//     .then((response) => {
//         books.value = response.data;
//     });

</script>