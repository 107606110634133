import { createRouter, createWebHistory } from "vue-router";

import NotFound from "../NotFound.vue";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";

import Quran from "../views/Quran.vue";
import Corpus from "../views/Corpus.vue";
import QAudio from "../views/QAudio.vue";
import QVideo from "../views/QVideo.vue";

import Book from "../views/Book.vue";
import Lecture from "../views/Lecture.vue";
import Clarity from "../views/Clarity.vue";
import Qna from "../views/Qna.vue";
import LohoQalamV from "../views/LohoQalamV.vue";

import AbrabicChar from "../views/ACharecters.vue";

import Alif from "../components//ArabicChar/Alif.vue";
import Bay from "../components/ArabicChar/Bay.vue";
import Jeem from "../components/ArabicChar/Jeem.vue";
import Dal from "../components/ArabicChar/Dal.vue";
import Wooh from "../components/ArabicChar/Wooh.vue";
import Zeeh from "../components/ArabicChar/Zeeh.vue";
import Khe from "../components/ArabicChar/Khe.vue";
import Tave from "../components/ArabicChar/Tave.vue";
import Yay from "../components/ArabicChar/Yay.vue";
import Qaaf from "../components/ArabicChar/Qaaf.vue";
import Lam from "../components/ArabicChar/Lam.vue";
import Meem from "../components/ArabicChar/Meem.vue";
import Noon from "../components/ArabicChar/Noon.vue";
import Seen from "../components/ArabicChar/Seen.vue";
import Aeen from "../components/ArabicChar/Aeen.vue";
import Feeh from "../components/ArabicChar/Feeh.vue";
import Savad from "../components/ArabicChar/Savad.vue";
import Heeh from "../components/ArabicChar/Heeh.vue";
import Reeh from "../components/ArabicChar/Reeh.vue";
import Seeh from "../components/ArabicChar/Seeh.vue";
import Teeh from "../components/ArabicChar/Teeh.vue";
import Zevah from "../components/ArabicChar/Zevah.vue";
// import Khe from "../components/ArabicChar/Khe.vue";
import Zal from "../components/ArabicChar/Zal.vue";
import Sawad from "../components/ArabicChar/Sawad.vue";
// import Zevah from "../components/ArabicChar/Zevah.vue";
import Ghan from "../components/ArabicChar/Ghan.vue";
// import ء from "../components/ء.vue";
import LohoQalam from "../views/LohoQalam.vue"
import Concepts from "../views/Concepts.vue"
import Chromopathy from "../views/Chromopathy.vue"

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: "/about",
            name: "about",
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import("../views/About.vue"),
        },
        {
            path: "/lohoqalam",
            name: "lohoqalam",
            component: LohoQalam,
        },
        {
            path: "/chromopathy",
            name: "chromopathy",
            component: Chromopathy,
        },
        {
            path: "/concepts",
            name: "concepts",
            component: Concepts,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
        },
        {
            path: "/achar",
            name: "achar",
            component: AbrabicChar,
        },
        {
            path: "/logout",
            name: "logout",
            component: {
                beforeRouteEnter(to, from, next) {
                    console.log({ from });
                    const destination = {
                        path: from.path || "/",
                        query: from.query,
                        params: from.params,
                    };
                    if (!from) {
                        console.log("no from");
                    }
                    console.log("running before hook");
                    store.logout("TOGGLE_MODAL");
                    next(destination);
                },
            },
        },
        {
            path: "/corpus",
            name: "corpus",
            component: Corpus,
        },
        {
            path: "/qaudio",
            name: "qaudio",
            component: QAudio,
        },
        {
            path: "/qvideo",
            name: "qvideo",
            component: QVideo,
        },
        {
            path: "/register",
            name: "register",
            component: Register,
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
        },
        {
            path: "/",
            name: "home",
            component: Home,
        },
        {
            path: "/book",
            name: "book",
            component: Book,
        },
        {
            path: "/lecture",
            name: "lecture",
            component: Lecture,
        },
        {
            path: "/lohoqalamV",
            name: "lohoqalamV",
            component: LohoQalamV,
        },
        {
            path: "/clarity",
            name: "clarity",
            component: Clarity,
        },
        {
            path: "/quran",
            name: "quran",
            component: Quran,
        },
        {
            path: "/qna",
            name: "qna",
            component: Qna,
        },
        {
            path: "/contact",
            name: "contact",
            component: Contact,
        },
        {
            path: "/:pathMatch(.*)",
            name: "notfound",
            component: NotFound,
        },
        // -------------------------------------------------------------------------
        {
            path: "/Alif.vue",
            name: "Alif",
            component: Alif,
        },
        {
            path: "/Bay.vue",
            name: "Bay",
            component: Bay,
        },
        {
            path: "/Jeem.vue",
            name: "Jeem",
            component: Jeem,
        },
        {
            path: "/Dal.vue",
            name: "Dal",
            component: Dal,
        },

        {
            path: "/Heeh.vue",
            name: "Heeh",
            component: Heeh,
        },
        {
            path: "/Wooh.vue",
            name: "Wooh",
            component: Wooh,
        },
        {
            path: "/Zeeh.vue",
            name: "Zeeh",
            component: Zeeh,
        },
        {
            path: "/Khe.vue",
            name: "Khe",
            component: Khe,
        },
        {
            path: "/Tave.vue",
            name: "Tave",
            component: Tave,
        },
        {
            path: "/Yay.vue",
            name: "Yay",
            component: Yay,
        },
        {
            path: "/Qaaf.vue",
            name: "Qaaf",
            component: Qaaf,
        },
        {
            path: "/Lam.vue",
            name: "Lam",
            component: Lam,
        },
        {
            path: "/Meem.vue",
            name: "Meem",
            component: Meem,
        },
        {
            path: "/Noon.vue",
            name: "Noon",
            component: Noon,
        },
        {
            path: "/Seen.vue",
            name: "Seen",
            component: Seen,
        },
        {
            path: "/Aeen.vue",
            name: "Aeen",
            component: Aeen,
        },
        {
            path: "/Feeh.vue",
            name: "Feeh",
            component: Feeh,
        },
        {
            path: "/Sawad.vue",
            name: "Sawad",
            component: Sawad,
        },
        {
            path: "/Qaaf.vue",
            name: "Qaaf",
            component: Qaaf,
        },
        {
            path: "/Reeh.vue",
            name: "Reeh",
            component: Reeh,
        },
        {
            path: "/Seeh.vue",
            name: "Seeh",
            component: Seeh,
        },
        {
            path: "/Teeh.vue",
            name: "Teeh",
            component: Teeh,
        },
        {
            path: "/Zeeh.vue",
            name: "Zeeh",
            component: Zeeh,
        },
        {
            path: "/Khe.vue",
            name: "Khe",
            component: Khe,
        },
        {
            path: "/Zal.vue",
            name: "Zal",
            component: Zal,
        },
        {
            path: "/Savad.vue",
            name: "Savad",
            component: Savad,
        },
        {
            path: "/Zevah.vue",
            name: "Zevah",
            component: Zevah,
        },
        {
            path: "/Ghan.vue",
            name: "Ghan",
            component: Ghan,
        },
    ],
});

export default router;
