/**
 * Created by Zura on 12/25/2021.
 */
import axios from "axios";

// axios.defaults.baseURL = `http://localhost:8000/api`;
if(window.location.host.includes("drazeemi"))
  axios.defaults.baseURL = `https://n.drazeemi.com/backend/public/index.php/api`;
else if(window.location.host.includes("localhost"))
  axios.defaults.baseURL = `http://localhost:8000/api/`;

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + ${store.state.user.token};
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

/*
axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.user.token}`
  return config;
})

axiosClient.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    store.commit('setToken', null)
    router.push({name: 'login'})
  }
  throw error;
})
*/
export default axios;

