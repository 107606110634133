<template>
  <header class="bg-white ">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 
       flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 " aria-label="Global">
      <!-- 0. Icon  -->
      <div class="flex justify-center mt-5">
        <img src="../assets/images/icon.svg" alt="Icon" class="max-w-32">
      </div>

      <!-- 1. Home -->
      <router-link to="./" class="text-sm font-semibold leading-6 text-gray-900">Home</router-link>

      <!-- 2. Videos Lectures -->
      <button id="dropdownDefaultButtonV" data-dropdown-toggle="dropdownV" class="text-sm font-semibold leading-6 text-gray-900 hover:bg-slate-600 
        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium 
        rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center 
        dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
        <font-awesome-icon icon="fa-solid fa-video" /> &nbsp; Video Lectures
        <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 4 4 4-4" />
        </svg>
      </button>
      <!-- Videos Dropdown menu -->
      <div id="dropdownV" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
          <li>
            <router-link to="/lohoqalamV"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Loh-o-Qalam</router-link>
          </li>
          <li>
            <router-link to="chromopathyV"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Chromopathy</router-link>
          </li>
          <li>
            <router-link to="/conceptsV"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Concepts</router-link>
          </li>
        </ul>
      </div>

      <!-- 3. Audio Lectures -->
      <router-link to="./lecture" class="text-sm font-semibold leading-6 text-gray-900">
        <font-awesome-icon icon="fa-solid fa-volume-high" />
        Audio Lectures</router-link>

      <!-- 4. Audio Q&A -->
      <router-link to="./qna" class="text-sm font-semibold leading-6 text-gray-900">
        Audio Q&A
      </router-link>

      <!-- 5. Books -->
      <router-link to="./book" class="text-sm font-semibold leading-6 text-gray-900">
        <font-awesome-icon icon="fa-solid fa-book" class="w-4 h-4 mr-2 text-blue-600 dark:text-blue-500"
          aria-hidden="true" />
        Books
      </router-link>

      <!-- 6. Conceptual Clarity -->
      <router-link to="./clarity" class="text-sm font-semibold leading-6 text-gray-900">Conceptual Clarity</router-link>

      <!-- 7. Youtube -->
      <button id="dropdownDefaultButtonYoutbe" data-dropdown-toggle="dropdownYoutbe" 
      class="text-sm font-semibold leading-6 text-gray-900 hover:bg-slate-600 
        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium 
        rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center 
        dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
        <font-awesome-icon icon="fa-brands fa-youtube" style="color: #e90707;" />
        &nbsp; Youtube
        <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 4 4 4-4" />
        </svg>
      </button>
      <!-- Videos Dropdown menu -->
      <div id="dropdownYoutbe" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
          <li>
            <router-link to="/lohoqalam"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Loh-o-Qalam</router-link>
          </li>
          <li>
            <router-link to="chromopathy"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Chromopathy</router-link>
          </li>
          <li>
            <router-link to="/concepts"
              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Concepts</router-link>
          </li>
        </ul>
      </div>

      <!-- 8. Quran -->
      <PopoverGroup class="hidden lg:flex lg:gap-x-12">
        <Popover class="relative">
          <!-- Quran -->
          <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
            @click="router.push('/quran')">
            <font-awesome-icon icon="fa-solid fa-book-quran"
              class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
            Quran
            <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel
              class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
              <div class="p-4">
                <div v-for="item in Quran" :key="item.name"
                  class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                  <div
                    class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <font-awesome-icon :icon="item.icon" class="w-4 h-4 mr-2 text-blue-600 dark:text-blue-500"
                      aria-hidden="true" />
                  </div>
                  <div class="flex-auto">
                    <router-link :to="item.to" class="block font-semibold text-gray-900">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </router-link>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  <a v-for="item in callsToAction" :key="item.name" :href="item.href" class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                    <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </div> -->
            </PopoverPanel>
          </transition>
        </Popover>

      </PopoverGroup>

      <!-- 9. About Us -->
      <router-link to="./about" class="text-sm font-semibold leading-6 text-gray-900">About Us</router-link>

    </nav>

    <!-- For mobile -->
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Dr Maqsood</span>
            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                <DisclosureButton
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Book
                  <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />
                </DisclosureButton>
                <DisclosurePanel class="mt-2 space-y-2">
                  <DisclosureButton v-for="item in [...products, ...callsToAction]" :key="item.name" as="a"
                    :href="item.href"
                    class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {{ item.name }}</DisclosureButton>
                </DisclosurePanel>
              </Disclosure>

              <a href="#"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Features</a>
              <a href="#"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Marketplace</a>
              <a href="#"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Company</a>
            </div>
            <div class="py-6">
              <a href="#"
                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log
                in</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import router from '../router'

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/vue/20/solid'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpellCheck, faVideo, faVolumeHigh, } from '@fortawesome/free-solid-svg-icons'
import { faFileAudio, } from '@fortawesome/free-regular-svg-icons'

import { faYoutube } from '@fortawesome/free-brands-svg-icons'

library.add(faSpellCheck, faFileAudio, faVideo, faYoutube, faVolumeHigh)

const Quran = [
  { name: 'ArabicChar', description: 'Get a better Allah message by understanding Arabic first', to: './achar', icon: "fa-solid fa-spell-check" },
  // { name: 'Reading', description: 'Sample Quran  ', to: '#', icon: CursorArrowRaysIcon },
  { name: 'Audio', description: 'Sample Audio Quranic Surah ', to: './qaudio', icon: "fa-regular fa-file-audio" },
  // { name: 'Video', description: 'Sample Video Quranic Surah', to: './qvideo', icon: "fa-solid fa-video" },
]

const Books = [

  { name: 'Chromopathy', href: './books/English/Chromopathy.pdf', size: '87M', description: '', icon: ChartPieIcon },
  { name: 'Lectures on Loh o Qalum', href: './books/English/Lectures_on_Loh_o_Qalum.pdf', size: '28M', description: '', icon: ChartPieIcon },
  { name: 'Loh o Qalum', href: './books/English/Loh_o_Qalum.pdf', size: '188M', description: '', icon: ChartPieIcon },
  { name: 'Roohani Namaaz', href: './books/English/Roohani_Namaaz.pdf', size: '426K', description: '', icon: ChartPieIcon },
  { name: 'Theory of Chromolucis', href: './books/English/Theory_of_Chromolucis.pdf', size: '20M', description: '', icon: ChartPieIcon },
  { name: 'Aik Safar apne Murad ke hamrah', href: './books/English/aik-safar-apne-murad-ke-hamrah.pdf', size: '3.1M', description: '', icon: ChartPieIcon },
  { name: 'Alsadaf', href: './books/English/Alsadaf.pdf', size: '68M', description: '', icon: ChartPieIcon },
  { name: 'Chromopthy', href: './books/English/Chromopthy.pdf', size: '120M', description: '', icon: ChartPieIcon },
  { name: 'Nizame Taleem', href: './books/English/Nizame-Taleem.pdf', size: '28M', description: '', icon: ChartPieIcon },
  { name: 'Shaoor ne Lashoor Sy kaha', href: './books/English/Shaoor-ne-Lashoor-Sy-kaha.pdf', size: '16M', description: '', icon: ChartPieIcon },
]

const Conceptuals = [
  { name: 'Loh o Qalam', description: 'Get a better understanding of Loh o Qalam', href: '#', icon: ChartPieIcon },
  { name: 'Silsila-e-Azeemia', description: 'Silsila-e-Azeemia Concepts & instructions ', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Naziriya Rang o Noor', description: 'Heal using Naziriya Rang o Noor', href: '#', icon: FingerPrintIcon },
  { name: 'Chromopathy', description: 'Heal using Chromopathy', href: '#', icon: SquaresPlusIcon },
]

// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]

const mobileMenuOpen = ref(false)
</script>