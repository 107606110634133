<template>
  <div class="p-10 justify-between h-screen w-screen">
    <Nav></Nav>
    <!-- Start of tabs -->
    <!-- <div class="border-b border-gray-200 dark:border-gray-700">
            <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 justify-between">
                <li class="mr-2">
                    <router-link to="./quran" @click="changeTab('quran')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-book-quran"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                        Quran
                    </router-link>
                </li>
                <li class="mr-2">
                    <router-link to="./book" @click="changeTab('book')"
                        class="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group"
                        aria-current="page">
                        <font-awesome-icon icon="fa-solid fa-book" class="w-4 h-4 mr-2 text-blue-600 dark:text-blue-500"
                            aria-hidden="true" />
                        Books
                    </router-link>
                </li>
                <li class="mr-2">
                    <router-link to="./lecture" @click="changeTab('lecture')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-person-chalkboard"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300 fas fa"
                            aria-hidden="true" />
                        Lectures
                    </router-link>
                </li>
                <li class="mr-2">
                    <router-link to="./qna" @click="changeTab('qna')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-question"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                        Q&A
                    </router-link>
                </li>
                <li class="mr-2">
                    <router-link to="./clarity" @click="changeTab('clarity')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-shapes"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                        Conceptual Clarity
                    </router-link>
                </li>
                <li class="mr-2">
                    <router-link to="./video" @click="changeTab('video')"
                        class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                        <font-awesome-icon icon="fa-solid fa-shapes"
                            class="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
                        Video
                    </router-link>
                </li>
                !-- <li>
                    <a
                        class="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">Disabled</a>
                </li> --
            </ul>
        </div> -->
        <!-- end of tabs -->

        <div v-if="flags.quran">
            <h1>Quran page</h1>
            <router-view></router-view>
        </div>
        <div v-if="flags.book">
            <router-view></router-view>
        </div>
        <div v-if="flags.lecture">
            <h1>Lecture page</h1>
            <router-view></router-view>
        </div>

        <div v-if="flags.qna">
            <h1>Question & Answer page</h1>
            <router-view></router-view>
        </div>
        <div v-if="flags.clarity">
            <h1>Conceptual Clarity page</h1>
            <router-view></router-view>
        </div>

    <Footer></Footer>
  </div>
</template>

<script setup>

import { ref, watch, onBeforeMount, computed } from 'vue';
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faPersonChalkboard, faQuestion, faBook, faShapes, faBookQuran, faLanguage
} from '@fortawesome/free-solid-svg-icons'

library.add(faPersonChalkboard, faQuestion, faBook, faShapes, faBookQuran, faLanguage
)

import useMainStore from "./stores/index";
const store = useMainStore();

const flags = ref({ quran: false, book: true, lecture: false, qna: false, clarity: false });

onBeforeMount(() => {
    store.getBooks;
    // console.log("store districts: "+JSON.stringify(store.state.districts));
    // console.log("Store-Book: " + JSON.stringify(store));
    store.getVideos;
    // console.log("store districts: "+JSON.stringify(store.state.districts));
    // console.log("Store-Video: " + JSON.stringify(store));
});

</script>

<style></style>



<style scoped>
</style>
