
<script setup>

import { ref, onBeforeMount, watch } from 'vue'
import axios from '../axios'

const videos = ref();
const total = ref(0);

const search = ref('');

onBeforeMount(() => {
    getYTVideos();
});

async function getYTVideos() {

  var response = '';
	setTimeout( response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL1t6vU6YCJsWkFvL0wqNeqr0s5ZZFlPVd&key=AIzaSyAtpVDywTuQH0sC8LU7BL3lIksSWes8RDc&maxResults=50'), 11000);
    videos.value = response.data;
    console.log("Response Youtube:"+ JSON.stringify(response.data));
    total.value = videos.value.pageInfo.totalResults;
}

function myFunc(item) {
    if (item.id.kind == 'youtube#video')
        return true;
}

</script>

<template>
    <div>

        <div class="bg-gray-100">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <div class="flex flex-row justify-between">
                        <div>
                            <h2 class="text-2xl font-bold text-gray-900">Chromopathy</h2>
                            <p class="text-xl">{{ total }} videos</p>
                        </div>

                        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form class="space-y-6" @submit.prevent="summitHandle" method="GET">
                                <div>
                                    <label for="search"
                                        class="block text-sm font-medium leading-6 text-gray-900">Search</label>
                                    <div class="mt-2">
                                        <input id="search" name="search" type="text" autocomplete="search" required=""
                                            v-model="search"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        <div v-for="video in videos.items" :key="video.id" class="group relative">
                            <h3 class="mt-6 text-sm text-gray-500">
                                <div class="flex flex-row p-1">
                                    <iframe width="560" height="315"
                                        :src="'https://www.youtube.com/embed/' + video.snippet.resourceId.videoId"
                                        :title="video.snippet.title" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </div>

                            </h3>
                            <p class="text-base font-semibold text-gray-900">Views: {{ video.snippet.description }};
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped></style>
