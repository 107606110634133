<template>
    <div>

        <div class="bg-gray-100">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <div class="flex flex-row justify-between">
                        <div>
                            <h2 class="text-2xl font-bold text-gray-900">Videos</h2>
                            <p class="text-xl">{{ total }} videos</p>
                        </div>

                        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form class="space-y-6" @submit.prevent="summitHandle" method="GET">
                                <div>
                                    <label for="search"
                                        class="block text-sm font-medium leading-6 text-gray-900">Search</label>
                                    <div class="mt-2">
                                        <input id="search" name="search" type="text" autocomplete="search" required=""
                                            v-model="search"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        <div v-for="video in videos.items" :key="video.id" class="group relative">
                            <!-- <div class="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                        <img :src="video.img" :alt="video.title" class="h-80 w-0 object-cover object-center" />
                        </div> -->
                            <h3 class="mt-6 text-sm text-gray-500">
                                <div class="flex flex-row p-1">
                                    <iframe width="560" height="315"
                                        :src="'https://www.youtube.com/embed/' + video.snippet.resourceId.videoId"
                                        :title="video.snippet.title" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>
                                </div>
                                <!-- <a :href="video.url">
                            <span class="absolute inset-0" />
                            {{ video.utitle }} {{ video.title }}{{ video.utitle2 }}
                        </a> -->
                            </h3>
                            <p class="text-base font-semibold text-gray-900">Views: {{ video.snippet.description }};
                                <!-- Old: {{ video.old }} Duration {{ video.time }} -->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, onBeforeMount, watch } from 'vue'
import axios from '../axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import useMainStore from "../stores/index";

const store = useMainStore();

const vURL = ref({});
const videos = ref();
const total = ref(0);

const search = ref('');

onBeforeMount(() => {
    getYTVideos();
});

async function getYTVideos() {

    //     fetch('')
    // .then(response => response.json())
    // .then(json => console.log(json))

    const response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL1t6vU6YCJsX6SlesjRzNjrTqcl_D0sAW&key=AIzaSyAtpVDywTuQH0sC8LU7BL3lIksSWes8RDc&maxResults=50');
    videos.value = response.data;
    total.value = videos.value.pageInfo.totalResults;
    // console.log('Videos:'+ JSON.stringify(vURL.value));
    // videos.value = vURL.value.items.filter(myFunc);
    // console.log('Videos:'+ JSON.stringify(videos.value));
}

function myFunc(item) {
    if (item.id.kind == 'youtube#video')
        return true;
}


// async function getClarity(calle) {
//   await axios.get("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL1t6vU6YCJsX6SlesjRzNjrTqcl_D0sAW&key=AIzaSyAtpVDywTuQH0sC8LU7BL3lIksSWes8RDc&maxResults=50")
//     .then((response) => {
//       videos.value = response.data;
//       // console.log(calle + ": Tehsils: " + JSON.stringify(tehsils.value));
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }

// const filteredList = () => {
//     if (search == "") {
//         console.log('Now in default mode');
//         flag = false;
//         getClarity("Default list");
//     }
//     else
//         axios.get("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PL1t6vU6YCJsX6SlesjRzNjrTqcl_D0sAW&key=AIzaSyAtpVDywTuQH0sC8LU7BL3lIksSWes8RDc" +
//             search)
//             .then((response) => {
//                 videos.value = response.data;
//                 flag = true;
//             });
// };

// watch(
//     () => search,
//     (search, prevSearch) => {
//         filteredList();
//     }
// );

</script>


<style scoped></style>
