<template>
    <div>
        <BreadCrumbs :crumbs="crumbs" />
        <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">

                <div class="px-4 sm:px-0">
                    <router-link to="/lohoqalam">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                        <button
                            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mb-5"
                            @click="rLoq.flag = !rLoq.flag">
                            Reading Session of Book Loh o Qalam
                        </button>
                    </h3>
                    </router-link>
                </div>


                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                        <button
                            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mb-5"
                            @click="Sharah_Loh_Qalam.flag = !Sharah_Loh_Qalam.flag">
                            Reading of Sharah Loh Qalam
                        </button>
                    </dt>
                    <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0" v-if="Sharah_Loh_Qalam.flag">
                        <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">Sno</span>
                                        <span class="truncate font-medium">Lecture</span>
                                        <span class="truncate font-medium ml-16">Page</span>
                                        <span class="flex-shrink-0 text-gray-400">Size</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">1</span>
                                        <span class="truncate font-medium">Lecture 1.amr</span>
                                        <span class="truncate font-medium ml-10">10</span>
                                        <span class="flex-shrink-0 text-gray-400">2.8M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 1.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">2</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium ml-10"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">3</span>
                                        <span class="truncate font-medium">Lecture 3.amr</span>
                                        <span class="truncate font-medium ml-10">29</span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 3.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 3_2.amr</span>
                                        <span class="truncate font-medium ml-10">33</span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 3_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">4</span>
                                        <span class="truncate font-medium">Lecture 4.amr</span>
                                        <span class="truncate font-medium ml-10">38</span>
                                        <span class="flex-shrink-0 text-gray-400">2.9M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 4.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">5</span>
                                        <span class="truncate font-medium">Lecture 5.amr</span>
                                        <span class="truncate font-medium ml-10">42</span>
                                        <span class="flex-shrink-0 text-gray-400">3.6M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 5.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 5_2.amr</span>
                                        <span class="truncate font-medium ml-10">45</span>
                                        <span class="flex-shrink-0 text-gray-400">3.3M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 5_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">6</span>
                                        <span class="truncate font-medium">Lecture 6.amr</span>
                                        <span class="truncate font-medium ml-10">56</span>
                                        <span class="flex-shrink-0 text-gray-400">3.3M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 6.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">7</span>
                                        <span class="truncate font-medium">Lecture 7.amr</span>
                                        <span class="truncate font-medium ml-10">68</span>
                                        <span class="flex-shrink-0 text-gray-400">2.8M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 7.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">8</span>
                                        <span class="truncate font-medium">Lecture 8.amr</span>
                                        <span class="truncate font-medium ml-10">75</span>
                                        <span class="flex-shrink-0 text-gray-400">5.5M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 8.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">10</span>
                                        <span class="truncate font-medium">Lecture 9.amr</span>
                                        <span class="truncate font-medium ml-10">80</span>
                                        <span class="flex-shrink-0 text-gray-400">2.9M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 9.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">11</span>
                                        <span class="truncate font-medium">Lecture 9_2.amr</span>
                                        <span class="truncate font-medium ml-10">85</span>
                                        <span class="flex-shrink-0 text-gray-400">3.3M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 9_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">12</span>
                                        <span class="truncate font-medium">Lecture 12.amr</span>
                                        <span class="truncate font-medium ml-10">99</span>
                                        <span class="flex-shrink-0 text-gray-400">2.9M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 12.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">13</span>
                                        <span class="truncate font-medium">lecture 13.amr</span>
                                        <span class="truncate font-medium ml-10">105</span>
                                        <span class="flex-shrink-0 text-gray-400">2.8M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture 13.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">14</span>
                                        <span class="truncate font-medium">Lecture 14.amr</span>
                                        <span class="truncate font-medium ml-10">111</span>
                                        <span class="flex-shrink-0 text-gray-400">3.1M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 14.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 14_2.amr</span>
                                        <span class="truncate font-medium ml-10">115</span>
                                        <span class="flex-shrink-0 text-gray-400">3.4M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 14_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">15</span>
                                        <span class="truncate font-medium">Lecture 15.amr</span>
                                        <span class="truncate font-medium ml-10">121</span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 15.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 15_2.amr</span>
                                        <span class="truncate font-medium ml-10">125</span>
                                        <span class="flex-shrink-0 text-gray-400">3.1M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 15_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">16</span>
                                        <span class="truncate font-medium">lecture 16.amr</span>
                                        <span class="truncate font-medium ml-10">135</span>
                                        <span class="flex-shrink-0 text-gray-400">3.5M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture 16.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">17</span>
                                        <span class="truncate font-medium">Lecture 17.amr</span>
                                        <span class="truncate font-medium ml-10">140</span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 17.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">18</span>
                                        <span class="truncate font-medium">Lecture 18.amr</span>
                                        <span class="truncate font-medium ml-10">146</span>
                                        <span class="flex-shrink-0 text-gray-400">2.9M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 18.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">19</span>
                                        <span class="truncate font-medium">Lecture 19.amr</span>
                                        <span class="truncate font-medium ml-10">153</span>
                                        <span class="flex-shrink-0 text-gray-400">3.1M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 19.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">20</span>
                                        <span class="truncate font-medium">Lecture 20_2.amr</span>
                                        <span class="truncate font-medium ml-10">164</span>
                                        <span class="flex-shrink-0 text-gray-400">3.3M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 20_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 20_3.amr</span>
                                        <span class="truncate font-medium ml-10">168</span>
                                        <span class="flex-shrink-0 text-gray-400">3.0M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 20_3.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">21</span>
                                        <span class="truncate font-medium">Lecture 21.amr</span>
                                        <span class="truncate font-medium ml-10">171</span>
                                        <span class="flex-shrink-0 text-gray-400">2.7M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 21.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 21_.amr</span>
                                        <span class="truncate font-medium ml-10">171</span>
                                        <span class="flex-shrink-0 text-gray-400">3.1M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 21_.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">22</span>
                                        <span class="truncate font-medium">Lecture 22.amr</span>
                                        <span class="truncate font-medium ml-10">179</span>
                                        <span class="flex-shrink-0 text-gray-400">3.0M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 22.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 22_2.amr</span>
                                        <span class="truncate font-medium ml-10">184</span>
                                        <span class="flex-shrink-0 text-gray-400">2.6M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 22_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">23</span>
                                        <span class="truncate font-medium">Lecture 23.amr</span>
                                        <span class="truncate font-medium ml-10">190</span>
                                        <span class="flex-shrink-0 text-gray-400">3.0M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 23.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 23_2.amr</span>
                                        <span class="truncate font-medium ml-10">200</span>
                                        <span class="flex-shrink-0 text-gray-400">1.4M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 23_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">24</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">25</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">26</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">27</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">28</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">29</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">30</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">31</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">32</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">33</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">34</span>
                                        <span class="truncate font-medium">Lecture 34.amr</span>
                                        <span class="truncate font-medium ml-10">294</span>
                                        <span class="flex-shrink-0 text-gray-400">3.1M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 34.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">35</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">36</span>
                                        <span class="truncate font-medium">lecture no 36.amr</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400">2.6M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture no 36.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">lecture 36 page no 309.amr</span>
                                        <span class="truncate font-medium ml-10">309</span>
                                        <span class="flex-shrink-0 text-gray-400">3.3M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture 36 page no 309.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">37</span>
                                        <span class="truncate font-medium">lecture 37.amr</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture 37.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">lecture n0 37 page no 350.amr</span>
                                        <span class="truncate font-medium ml-10">350</span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture n0 37 page no 350.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">38</span>
                                        <span class="truncate font-medium">Lecture 38.amr</span>
                                        <span class="truncate font-medium ml-10">320</span>
                                        <span class="flex-shrink-0 text-gray-400">3.3M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 38.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">Lecture 38_2.amr</span>
                                        <span class="truncate font-medium ml-10">323</span>
                                        <span class="flex-shrink-0 text-gray-400">3.2M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/Lecture 38_2.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">39</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/<br>"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400">40</span>
                                        <span class="truncate font-medium">lecture 40.amr</span>
                                        <span class="truncate font-medium"><br></span>
                                        <span class="flex-shrink-0 text-gray-400">3.9M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture 40.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                            <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <div class="ml-4 flex min-w-0 flex-1 gap-2">

                                        <span class="flex-shrink-0 text-gray-400"><br></span>
                                        <span class="truncate font-medium">lecture no 40 page 339.amr</span>
                                        <span class="truncate font-medium ml-10">339</span>
                                        <span class="flex-shrink-0 text-gray-400">3.9M</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="/src/assets/audio/Sarah_loh_o_qalam/lecture no 40 page 339.amr"
                                        class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>

                        </ul>
                    </dd>
                </div>

                <div class="px-4 sm:px-0">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                        <button
                            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mb-5"
                            @click="QAudios.flag = !QAudios.flag">
                            Quranic Audios
                        </button>
                    </h3>
                </div>
                <div class="mt-6 border-t border-gray-100" v-if="QAudios.flag">
                    <dl class="divide-y divide-gray-100">

                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Audios</dt>
                            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                                    <li v-for="(audio, key) in audios" :key="key"
                                        class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div class="flex w-0 flex-1 items-center">
                                            <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                            <div class="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span class="truncate font-medium">{{ audio.name }}</span>
                                                <span class="flex-shrink-0 text-gray-400">{{ audio.size }}</span>
                                            </div>
                                        </div>
                                        <div class="ml-4 flex-shrink-0">
                                            <!-- new URL('../assets/audio/Discussion/'+audio.name, import.meta.url).href -->
                                            <a :href="'/src/assets/audio/' + audio.category + '/' + audio.name"
                                                class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                        </div>
                                    </li>
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>


            </dl>
        </div>
    </div>
</template>

<script setup>

import { reactive } from "vue"
import { PaperClipIcon } from '@heroicons/vue/20/solid'
import { ref, computed, onBeforeMount } from 'vue'
import axios from '../axios.js'

import BreadCrumbs from "../components/BreadCrumbs.vue"

const audios = ref({})

const Sharah_Loh_Qalam = reactive({ flag: false });
const QAudios = reactive({flag: false});
const rLoq = reactive({flag: false});

onBeforeMount(() => {
    getQAudio();
})

async function getQAudio() {
    const response = await axios.get('/qna/Loh_o_qalam');
    audios.value = response.data;

}

const crumbs = [
    // { cat: 'middle', title: 'Audio', url: '/qaudio'},
    { cat: 'end', title: 'Audio', url: '/qaudio'},
];
</script>